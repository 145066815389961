import React from 'react'
import SbEditable from '../../utils/sb-editable'
import useScript from '../../hooks/useScript'
import { useStaticQuery, graphql } from 'gatsby'
import { renderBloks } from 'utils'

const Footer = ({ children }) => {
  useScript({
    src: 'https://platform.linkedin.com/in.js',
    async: true,
    type: 'text/javascript',
    text: 'lang: en_US',
  })

  return (
    <div className="footer">
      <div className="container-fluid">
        <div className="footer__container">{children}</div>
        <div className="footer__copyright">
          <div className="footer__copyright__extra">
            <script type="IN/FollowCompany" data-id={process.env.GATSBY_LINKEDIN_COMPANY_ID} />
          </div>
          <p>© {new Date().getFullYear()} Sensitile Systems, All Rights Reserved.</p>
          <p>All of our Materials & Solutions are 100% Sourced & Manufactured in the USA</p>
        </div>
      </div>
    </div>
  )
}

export const FooterColumn = ({ name, children }) => (
  <div className="footer__column">
    <span className="footer__header">{name}</span>
    {children}
  </div>
)

export const EditableFooterColumn = ({ blok }) => (
  <SbEditable content={blok}>
    <FooterColumn name={blok.name}>{renderBloks(blok.content)}</FooterColumn>
  </SbEditable>
)

export const EditableFooter = ({ blok }) => (
  <SbEditable content={blok}>
    <Footer>
      {blok.columns.map((column) => (
        <EditableFooterColumn key={column._uid} blok={column} />
      ))}
    </Footer>
  </SbEditable>
)

export const FooterContainer = () => {
  const { storyblokEntry: { content } } = useStaticQuery(graphql`
    query FooterQuery {
      storyblokEntry(slug: { eq: "site-footer" }) {
        content
      }
    }
  `)
  return <EditableFooter blok={JSON.parse(content)} />
}

export default Footer
